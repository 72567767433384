@import "@fuse/scss/fuse.scss";

app-fuse-sidebar {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 280px;
    min-width: 280px;
    max-width: 280px;
    z-index: 1000;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);

    @include media-breakpoint("xs") {
        min-width: 0 !important;
        max-width: 80vw !important;
        width: 80vw !important;
    }

    &.left-positioned {
        left: 0;
        transform: translateX(-100%);
    }

    &.right-positioned {
        right: 0;
        transform: translateX(100%);
    }

    &.open {
        transform: translateX(0);
    }

    &.locked-open {
        position: relative !important;
        transform: translateX(0) !important;
    }

    &.folded {
        position: absolute !important;
        top: 0;
        bottom: 0;
    }

    &.animations-enabled {
        transition-property: transform, width, min-width, max-width;
        transition-duration: 150ms;
        transition-timing-function: ease-in-out;
    }

    @media print {
        display: none !important;
    }
}

.fuse-sidebar-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    opacity: 0;
}